<template>
  <b-container>
    <b-card>

      <validation-observer
        ref="simpleRules"
        #default="{ invalid }"
      >
        <b-form-group
          label="Empresas"
          label-for="companies"
        >
          <multiselect
            v-model="companies"
            :options="customers"
            class="mb-1"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Selecione uma empresa"
            select-label=""
            selected-label=""
            deselect-label=""
            label="name"
            track-by="name"
          >

            <span slot="noResult">
              Nenhuma Empresa Encontrada
            </span>
          </multiselect>
        </b-form-group>

        <b-form-group
          label="Email"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="email"
              placeholder="Email"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          :disabled="invalid"
          variant="primary"
          @click="createToken"
        >
          Enviar Email
        </b-button>
      </validation-observer>
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BCard, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import Multiselect from 'vue-multiselect';
import BiService from '@/service/bi/index';

import '@validations';

export default {
    components: {
        BContainer, Multiselect, BCard, BFormInput, BFormGroup, BButton, ValidationProvider, ValidationObserver,
    },

    data() {
        return {
            customers: [],
            companies: [],
            email: '',
        };
    },

    async mounted() {
        this.callLoading(true);
        const response = await Promise.all([
            BiService.getAllCustomers(),
            BiService.clientSacker({
                page: 9, pageSize: 500, name: '', document: '',
            }),
        ]);

        const responseUnique = (response.map(item => item.data.data).flat(1));

        this.customers = responseUnique.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({
            name: `${item.name} - ${item.document}`,
            value: item.document,
        }));
        this.callLoading(false);
    },

    methods: {
        async createToken() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente enviar o email?');

            if (!isConfirmed) {
                return;
            }

            const { status } = await BiService.sendToken({
                documents: this.companies.map(item => item.value),
                email: this.email,
            });

            if (status === 200) {
                this.modalSuccess('Emails enviados com sucesso');
            } else {
                this.modalError('Erro ao enviar emails');
            }
        },
    },
};
</script>

<style lang="scss">

.multiselect__option--highlight {
    background: #2E2C6C !important;
}

.multiselect__tag {
    background: #2E2C6C !important;
}

.dark-layout {
    .multiselect__tags {
        background-color: #283046;
        border-color: #3b4253;
    }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
